import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { interval } from 'rxjs';
const numbers = interval(10000);
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;
  public currentUser:any;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _httpService:HttpService
    ) {
    this.onApiDataChange = new BehaviorSubject('');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log('current user',this.currentUser)
    
    if(this.currentUser.id!=undefined){
      this.getNotificationsData(this.currentUser.id);
      this.intervalLoopGetData()
    }


  }
  async intervalLoopGetData(){
    const takeFourNumbers = await numbers.pipe();
    takeFourNumbers.subscribe(x => {
      if(this.currentUser.id!=undefined){
        this.getNotificationsData(this.currentUser.id);
      }
    });
  }
  /**
   * Get Notifications Data
   */
  getNotificationsData(id:string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpService.getLocalStorageInfo("token").then(token=>{
        if(token!=undefined){
          console.log('verify token',token)
          let value = {token:token,token_type:'1'}
          this._httpService.getHttpJsonPost('verified-token',value,token).then(res=>{
            if(res.result.status!=false){
              this._httpService.getHttpJsonGet('notification/user/'+id,token).then(res=> {
                this.apiData = res.result.result;
                console.log('api data1 : ',res.result.result)
                this.onApiDataChange.next(this.apiData);
                resolve(this.apiData);
              });
            }else{
              console.log('verify false',res)
            }
          })
        }
      })


    });
  }
}
